.videos-wrapper{
    display: flex;
    flex-wrap: wrap;
    row-gap: 30px;
    margin-bottom: 30px;
    width: calc(100% + 30px);
    @include maxWidth768(){
        row-gap: unset;
        margin-bottom: 20px;
        width: 100%;
    }
    .video-item{
        width: calc(20% - 30px);
        margin-left: 30px;
        background-color: var(--input);
        padding: 16px 20px;
        border-radius: 12px;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        @include maxWidth1340(){
            width: calc(25% - 30px);
        }
        @include maxWidth991(){
            width: calc(100%/3 - 30px);
        }
        @include maxWidth768(){
            width: 100%;
            margin-left: 0;
            margin-bottom: 12px;
            &:last-of-type{
                margin-bottom: 0;
            }
        }
        &:nth-child(5n + 1){
            margin-left: 0;
            @include maxWidth1340(){
                margin-left: 30px;
            }
            @include maxWidth768(){
                margin-left: 0;
            }
        }
        &:nth-child(4n + 1){
            @include maxWidth1340(){
                margin-left: 0px;
            }
            @include maxWidth991(){
                margin-left: 30px;
            }
            @include maxWidth768(){
                margin-left: 0;
            }
        }
        &:nth-child(3n + 1){
            @include maxWidth991(){
                margin-left: 0px;
            }
            @include maxWidth768(){
                margin-left: 0;
            }
        }
        &__info{
            &-line{
                .selector{
                    margin-right: 8px;
                    color: var(--text-main);
                }
                .text{
                    color: var(--text-secondary);
                }
            }
        }
        .additional-btn{
            width: 24px;
            height: 24px;
            cursor: pointer;
            position: relative;
            img{
                width: 100%;
            }
            &:hover .dropdown {
                display: block;
            }
        }
        &__dropdown {
            border-radius: 12px;
            right: -20px;
            z-index: 10;
            a {
                background: var(--background);
                &:hover {
                    background: var(--elements);
                }
            }
        }
    }
}

