//profile
.profile-card{
    margin-bottom: 20px;
    @include maxWidth991(){
        padding: 30px;
    }
    @include maxWidth768(){
        padding: 20px 16px !important;
    }
}
.upload-photo{
    @include flex-space-between();
    @include maxWidth768(){
        flex-direction: column;
    }
    &_left{
        display: flex;
        align-items: center;
        @include maxWidth768(){
            flex-direction: column;
        }
    }
    .userphoto{
        width: 100px;
        height: 100px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 30px;
        @include maxWidth768(){
            margin-right: 0;
            margin-bottom: 20px;
        }
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
.upload-file{
    cursor: pointer;
    border-radius: 14px;
    border: 1px dashed var(--link);
    padding: 38px;
    display: flex;
    align-items: center;
    position: relative;
    @include maxWidth768(){
        padding: 15px 43.5px;
        margin-bottom: 20px;
    }
    @include maxWidth560(){
        padding: 15px 25.5px;
    }
    @include maxWidth420(){
        padding: 15px 15px;
    }
    &__info{
        display: flex;
        align-items: center;
        img{
            width: 24px;
            height: 24px;
            margin-right: 14px;
            @include maxWidth768(){
                margin-right: 10px;
            }
        }
        span{
            color: var(--text-secondary);
            b{
                color: var(--text-main);
            }
        }
    }
    &__text{
        @include flex-column();
        align-items: center;
        text-align: center;
        .mobile{
            display: none;
            color: var(--text-main);
        }
        @include maxWidth768(){
            .desktop{
                display: none;
            }
            .mobile{
                display: block;
            }
        }
    }
    .filesize{
        position: absolute;
        right: -235px;
        top: 50%;
        transform: translateY(-50%);
        color: var(--text-secondary);
        display: block;
        @include maxWidth991(){
            right: -195px;
        }
        @include maxWidth768(){
            position: unset;
            transform: unset;
            margin-top: 4px;
        }
    }
}
.flex-profile{
    display: flex;
    width: 75%;
    @include maxWidth1340(){
        width: 100%;
    }
    @include maxWidth768(){
        flex-direction: column;
    }
    .title-col{
        display: flex;
        align-items: center;
        margin-bottom: 24px;
        @include maxWidth768(){
            margin-bottom: 14px;
        }
        img{
            width: 24px;
            height: 24px;
            margin-right: 8px;
        }
        span{
            color: var(--text-secondary);
        }
    }
    &__col{
        width: calc(50% - 37px);
        @include maxWidth1340(){
            width: calc(50% - 20px);
        }
        @include maxWidth991(){
            width: calc(50% - 10px);
        }
        @include maxWidth768(){
            width: 100%;
        }
        &:nth-of-type(odd){
            margin-right: 74px;
            @include maxWidth1340(){
                margin-right: 40px;
            }
            @include maxWidth991(){
                margin-right: 20px;
            }
            @include maxWidth768(){
                margin-right: 0;
                margin-bottom: 20px;
            }
            .input_div{
                margin-bottom: 24px;
                @include maxWidth768(){
                    margin-bottom: 12px;
                }
            }
        }
        .input_div{
            width: 100%;
            .input{
                width: 100%;
            }
        }
        .flex-btns{
            display: flex;
            button{
                &:first-of-type{
                    margin-right: 18px;
                    width: calc(60% - 9px);
                    @include maxWidth768(){
                        margin-right: 12px;
                        width: calc(60% - 6px);
                    }
                }
                &:last-of-type{
                    width: calc(40% - 9px);
                    @include maxWidth768(){
                        width: calc(40% - 6px);
                    }
                }
            }
        }
        .btn{
            @include maxWidth991(){
                padding: 16px 20px;
            }
            @include maxWidth420(){
                font-size: 13px;
                padding: 16px;
            }
        }
        
    }
}
.security-card{
    @include maxWidth991(){
        padding: 30px !important;
    }
    @include maxWidth768(){
        padding: 20px 20px 32px 20px !important;
    }
    .form{
        position: relative;
        @include maxWidth1340(){
            flex-direction: unset;
        }
        @include maxWidth768(){
            width: 100%;
        }
        .input_div{
            @include maxWidth1340(){
                width: 70%;
            }
            @include maxWidth768(){
                width: 80%;
                margin-bottom: 12px;
            }
            @include maxWidth560(){
                width: 100%;
            }
            .icon_eye {
                @include maxWidth560(){
                    right: 105px;
                } 
            }
        }
        .btn{
            position: absolute;
            right: -125px;
            width: auto;
            @include maxWidth1340(){
                right: 0;
                width: 30%;
            }
            @include maxWidth768(){
                width: 20%;
            }
            @include maxWidth560(){
                width: 30%;
            }
        }
    }
}
.delete{
    @include maxWidth768(){
        display: flex;
        align-items: center;
    }
    span{
        display: none;
        @include maxWidth768(){
            display: block;
            margin-right: 8px;
            color: $status_red;
        }
    }
    svg{
        width: 24px;
        height: 24px;
        cursor: pointer;
        @include maxWidth768(){
            path{
                fill: $status_red;
            }
        }
    }
    &:hover {
        svg path {
            fill: $status_red;
        }
    }
}