body.ReactModal__Body--open {
    overflow: hidden;
}
.container {
    width: 1320px;
    max-width: 100%;
    margin: 0 auto;
    padding: 0 20px;
    @include maxWidth1340(){
        width: 960px;
        padding: 0 15px;
    }
    @include maxWidth991(){
        width: 960px;
    }
    @include maxWidth768(){
        width: 100%;
        padding: 0 10px;
    }
}
.mobile-hidden {
    @include maxWidth991(){
        display: none;
    }
}
.desktop-hidden {
    display: none;
    @include maxWidth991(){
        display: block;
    }
}
.layout {
    .content {
        min-height: calc(100vh - 160px);
        padding: 40px 0 80px;
        @include maxWidth991() {
            min-height: calc(100vh - 120px);
            padding: 40px 0;
        }
    }
}
.layout-auth {
    .content {
        @include flex-center();
    }
}


// forms
.form-wrap{
    position: relative;
    width: 760px;
    min-height: calc(100vh - 160px - 140px);
    margin: auto;
    background-color: var(--elements);
    border-radius: 20px;
    @include flex-center;
    text-align: center;
    padding: 50px 200px;
    overflow: hidden;
    @include maxWidth991(){
        min-height: calc(100vh - 120px - 80px);
    }
    @include maxWidth768(){
        width: 100%;
        background-color: unset;
        padding: unset;
        height: auto;
    }
    .arrow{
        position: absolute;
        top: 30px;
        left: 30px;
        @include maxWidth768(){
            top: 16px;
            left: 16px;
        }
        img{
            width: 32px;
            height: 32px;
        }
    }
    .back-link {
        position: absolute;
        top: 30px;
        left: 30px;
    }
    &__profile-photo {
        padding-left: 130px;
        padding-right: 130px;
    }
    &__loader {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: rgba(0,0,0, .8);
        z-index: 11;
        @include flex-center();
    }
}
.form{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include maxWidth768(){
        width: 280px;
    }
    &__title{
        margin-bottom: 40px;
        @include maxWidth768(){
            margin-bottom: 24px;
        }
    }
    &__descr{
        margin-bottom: 24px;
        @include maxWidth768(){
            margin-bottom: 16px;
        }
    }
    .input_div{
        margin-bottom: 24px;
        width: 100%;
        @include maxWidth768(){
            margin-bottom: 16px;
            width: auto;
        }
        .input{
            width: 100%;
            @include maxWidth768(){
                width: 280px;
            }
        }
    }
    .btn{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 24px;
        @include maxWidth768(){
            width: 280px;
            margin-bottom: 16px;
        }
    }
    .resend_request{
        margin-bottom: 24px;
        color: var(--text-light);
        &__link{
            color:  var(--text-main);
        }
    }
    &__error {
        color: $status_red;
        margin-top: 12px;
    }
}
.question{
    display: flex;
    span{
        color: var(--text-main);
    }
    &__link{
        color: var(--link);
        margin-left: 16px;

    }
}


.back-link{
    display: inline-flex;
    align-items: center;
    margin-bottom: 40px;
    @include maxWidth768(){
        margin-bottom: 24px;
    }
    img{
        width: 24px;
        height: 24px;
        margin-right: 8px;
        @include maxWidth768(){
            width: 20px;
            height: 14px;
        }
    }
    span{
        color: var(--text-main);
    }
}
.card-widget{
    background-color: var(--elements);
    border-radius: 20px;
    padding: 50px;
    &__heading{
        padding-bottom: 30px;
        @include maxWidth768(){
            padding-bottom: 20px;
            text-align: center;
        }
    }
    &__empty{
        padding: 106px 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        @include maxWidth768(){
            padding: 160px 40px;
        }
        span{
            text-align: center;
            color: var(--text-secondary);
        }
    }
    &__subscribe-card{
        display: flex;
        flex-direction: column;
        align-items: center;
        @include maxWidth768(){
            padding: 50px 20px;
        }
        .card-widget__title{
            text-align: center;
            padding-bottom: 40px;
            @include maxWidth768(){
                padding-bottom: 20px;
            }
        }
    }
    &__videos-card{
        @include maxWidth768(){
            padding: 20px 20px 32px;
        }
        .card-widget__title{
            @include maxWidth768(){
                margin-bottom: 20px;
                border-bottom: 1px solid var(--line-light);
            }
        }
    }
}
.benefits-col{
    &__row{
        display: flex;
        align-items: center;
        margin-bottom: 24px;
        img{
            width: 24px;
            height: 24px;
            margin-right: 8px;
        }
    }
    .btn_primary{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 40px;
    }
}
.pagination-wrap{
    display: flex;
    align-items: center;
    width: auto;
    justify-content: center;
    .pagination{
        display: flex;
        align-items: center;
        a{
            width: 50px;
            height: 50px;
            border-radius: 14px;
            border: 2px solid var(--link);
            display: flex;
            align-items: center;
            justify-content: center;
            color: var(--link);
            margin-right: 8px;
            &.active{
                background-color: var(--link);
                border: unset;
                color: $btn_text;
            }
            &:last-of-type{
                margin-right: 0;
            }
        }
        span{
            color: var(--text-light);
            padding: 0 8px;
            margin-right: 8px;
        }
    }
    .arrow{
        width: 24px;
        height: 24px;
        &.prev{
            margin-right: 8px;
        }
        &.next{
            margin-left: 8px;
        }
    }
}


.profile-photo{
    width: 100%;
    &__heading{
        color:  var(--text-main);
        margin-bottom: 30px;
        @include maxWidth768(){
            margin-bottom: 24px;
        }
    }
    &__description{
        display: block;
        color: var(--text-secondary);
        margin-bottom: 30px;
        @include maxWidth768(){
            margin-bottom: 24px;
        }
    }
}
.upload-step{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .popup__heading{
        margin-bottom: 24px;
    }
    .photo-ranges{
        width: 100%;
    }
    .btn{
        @include maxWidth768(){
            width: 100%;
        }
    }
    .btns-flex{
        width: 100%;
        display: flex;
        justify-content: center;
        .btn{
            width: calc(50% - 10px);
            @include maxWidth768(){
                width: calc(50% - 6px);
                padding: 12px 23px;
                border-radius: 10px;
                font-size: 14px;
                line-height: 16px;
            }
            &:first-of-type{
                margin-right: 20px;
                @include maxWidth768(){
                    margin-right: 12px;
                }
            }
        }
    }
}
.change-photo-profile{
    width: 320px;
    height: 320px;
    border-radius: 50%;
    background-color:  var(--input);
    background-image: url(../img/Grid.png);
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid $status_blue;
    overflow: hidden;
    background-color: var(--input);
    margin: 0 auto 24px;
    cursor: pointer;
    @include maxWidth768(){
        width: 172px;
        height: 172px;
        margin: 0 auto 16px;
    }
    img{
        width: 100%;
        max-width: none;
    }
    &__text-part{
        width: 85%;
        border: medium dashed var(--link);
        padding: 20px 25px;
        border-radius: 14px;
        @include maxWidth768(){
            padding: 12px 15.5px 14px;
        }
        .text_small {
            display: block;
            color: var(--text-secondary);
            p{
                display: inline;
                color:  var(--text-main);
            }
            @include maxWidth768(){
                span{
                    display: none;
                }
            }
        }
    }
    .dropzone {
        @include flex-center();
    }
}
.photo-range{
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 20px;
    &:last-of-type{
         margin-bottom: 60px;
         @include maxWidth768(){
             margin-bottom: 24px;
         }
    }
    &__title{
         color: var(--text-main);
         margin-bottom: 16px;
    }    
    &__input{
         width: 100%;
         input{
             -webkit-appearance: none;
             appearance: none; 
             width: 100%;
             cursor: pointer;
             outline: none;
             overflow: hidden;
             margin-left: auto;
             margin-right: auto;
             margin-top: 0;
             margin-bottom: 0;
             display: block;
             border-radius: 10px;
             padding: 7px 0 7px 2px;
             background-color: unset;
             &[type=range]:disabled{
                 cursor: unset;
             }
             &[type=range]:disabled::-webkit-slider-runnable-track {
                 height: 14px;
                 background: var(--input);
                 border: unset;
             }

             &[type=range]:disabled::-moz-range-track {
                 height: 14px;
                 background: var(--input);
                 border: unset;
             }

             &[type=range]:disabled::-webkit-slider-thumb {
                 background-color: #232F3F;
                 border: unset;
                 box-shadow: 0 0 0 3px inset var(--input);
             }

             &[type=range]:disabled::-moz-range-thumb {
                 background-color: #232F3F;
                 border: unset;
                 box-shadow: 0 0 0 3px inset var(--input);
             } 
             &::-webkit-slider-runnable-track {
                 height: 14px;
                 background: var(--input);
                 border: 1px solid var(--text-light);
                 border-radius: 10px;
             }
             &::-moz-range-track {
                 height: 14px;
                 background:  var(--input);
                 border: 1px solid var(--text-light);
                 border-radius: 10px;
             }
             &[type=range]::-webkit-slider-thumb {
                 -webkit-appearance: none;
                 appearance: none; 
                 height: 24px;
                 width: 24px;
                 margin-top: -6px;
                 transition: .2s ease-in-out;
                 background-color: #fff;
                 border-radius: 50%;
                 border: 0.2px solid var(--text-light);
                 box-shadow: 0 0 0 3px inset rgba(52, 65, 83, 1);
             }
             &::-moz-range-thumb {
                 height: 24px;
                 width: 24px;
                 background-color: #fff;
                 border-radius: 50%;
                 border: 0.2px solid var(--text-light);
                 box-shadow: 0 0 0 3px inset rgba(52, 65, 83, 1);
                 transition: .2s ease-in-out;
             }
         }
    } 
}
.profile-photo{
    width: 100%;
    &__heading{
        color:  var(--text-main);
        margin-bottom: 30px;
        @include maxWidth768(){
            margin-bottom: 24px;
        }
    }
    &__description{
        display: block;
        color: var(--text-secondary);
        margin-bottom: 30px;
        @include maxWidth768(){
            margin-bottom: 24px;
        }
    }
}
  

.network_error{
    font-family: "SF Pro Display", sans-serif;
    font-size: 16px;
    background-color: #1D2836;
    color: #E9F0F5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    padding: 20px;
    h1{
        font-size: 24px;
        line-height: 30px;
        font-weight: 700;
    }
}

.cookieBanner {
    position: fixed;
    bottom: 0;
    left:0;
    width: 100%;
    background: var(--elements);
    border-top: 1px solid var(--line-hard);
    padding: 20px;
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 60px;
    @include maxWidth768(){
        flex-direction: column;
        padding: 10px 20px;
        align-items: flex-start;
        justify-content: flex-start;
    }
  }
  .cookieText{
    margin: 0;
    font-size: 16px;
    color: var(--text-main);
    @include maxWidth768(){
        margin-bottom: 30px;
        max-width: 80%;
    }
  }
  .cookieLink {
    font-size: 16px;
    font-weight: 500;
    color: var(--text-secondary);
  }
  .cookieBtns {
    display: flex;
    gap: 10px;
    @include maxWidth768(){
       align-items: flex-start;
       justify-content: flex-start;
    }
  }
  .cookieClose{
    width: 50px;
    height: 50px;
    @include maxWidth768(){
        position: absolute;
        right: 10px;
        top: 10px;
    }
  }


