//Night theme colors
    $c_bg: #1D2836 !default;
    $c_el: #232F3F;
    $c_el_hover: #344153;
    $c_input: #344153;
    $c_text_main: #E9F0F5;
    $c_text_sec: #94A8B4;
    $c_text_light: #536C7B;
    $c_link: #4DB0F9;
    $c_line_hard: #37485F;
    $c_line_light: #2A394F;

//Day theme colors
// [theme='light'] {
    $c_bg_day: #E9F0F4;
    $c_el_day: #FFFFFF;
    $c_el_hover_day: #F0F5F9;
    $c_input_day: #F0F5F9;
    $c_text_main_day: #233A4A;
    $c_text_sec_day: #6D8597;
    $c_text_light_day: #A3B3BE;
    $c_link_day: #4DB0F9;
    $c_line_hard_day: #A7BECE;
    $c_line_light_day: #EEF4F8;
// }
//theme colors

/*** Neutral colors ***/
//Buttons
$btn_main: #4DB0F9;
$btn_hover: #73C4FF;
$btn_pressed: #93D1FF;
$btn_disabled: #A7B8C5;
$btn_text: #FFFFFF;
//Status
$status_blue: #4DB0F9;
$status_red: #FF6767;
$status_yellow: #FFDE69;
$status_green: #6BDE59;
//True neutrals
$color_bright: #F0F5F8;
$color_light_bright: #BFD1DF;
$color_nearly_black: #030F18;
$color_white: #FFFFFF;
//Chat window
$chat_top_panel: rgba(27, 49, 69, .9);
$chat_main_panel: rgba(27, 49, 69, .8);
$chat_additional_panel: rgba(27, 49, 69, .7);
$chat_bg: #22374A;