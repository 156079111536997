@tailwind base;
@tailwind components;
@tailwind utilities;

@import url(assets/fonts/SFPro/fonts.css);

body {
  margin: 0;
  font-family: 'SF Pro Display', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

