@mixin maxWidth1600() {
    @media screen and (max-width: 1600px) {
        @content;
    }
}
@mixin maxWidth1340() {
    @media screen and (max-width: 1340px) {
        @content;
    }
}
@mixin maxWidth991() {
    @media screen and (max-width: 991px) {
        @content;
    }
}
@mixin maxWidth768() {
    @media screen and (max-width: 768px) {
        @content;
    }
}
@mixin maxWidth560() {
    @media screen and (max-width: 560px) {
        @content;
    }
}
@mixin maxWidth420() {
    @media screen and (max-width: 420px) {
        @content;
    }
}
@mixin flex-row {
    display: flex;
    flex-direction: row;
}

@mixin flex-wrap {
    display: flex;
    flex-wrap: wrap;
}

@mixin flex-column {
    display: flex;
    flex-direction: column;
}

@mixin flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-space-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@mixin gap($rowGap, $columnGap: $rowGap) {
    row-gap: $rowGap;
    column-gap: $columnGap;
}

@mixin square($size) {
    width: $size;
    height: $size;
}

@mixin mobile() {
    @media (max-width: 1024px) {
        @content;
    }
}

@mixin desktop() {
    @media (min-width: 1025px) {
        @content;
    }
}

@mixin imgWrapper($width, $height: null) {
    line-height: 0;
    min-width: $width;
    width: $width;

    @if $height != null {
        height: $height;
    }

    img {
        width: 100%;
        height: auto;
    }
}

@mixin squareFlex($size) {
    min-width: $size;
    min-height: $size;
    max-width: $size;
    max-height: $size;
}

@mixin flexWidth($width) {
    min-width: $width;
    max-width: $width;
}

@mixin flexWidthReset {
    min-width: initial;
    max-width: initial;
}
