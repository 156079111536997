.card-widget{
    &__friends-card{
        @include maxWidth768(){
            background-color: unset;
            padding: unset !important;
        }
        .card-widget__title{
            border-bottom: 1px solid var(--line-light);
        }
    }
    &__title{
        color: var(--text-main);
        padding-bottom: 30px;
        @include maxWidth768(){
            padding-bottom: 20px;
        }
    }
}
.status-friends-wrap{
    border-bottom: 1px solid var(--line-light);
    padding-bottom: 30px;
    @include maxWidth768(){
        padding: 20px 20px 32px;
        background-color: var(--elements);
        border-radius: 16px;
        margin-bottom: 16px;
        border-bottom: unset;
        &:last-of-type{
            margin-bottom: 0;
        }
    }
    &:last-of-type{
        border-bottom: unset;
        padding-bottom: 0;
        @include maxWidth768(){
            padding-bottom: 32px;
        }
    }
    &__heading{
        padding: 30px 0;
        @include maxWidth991(){
            padding: 20px 0 0 0;
        }
        .status{
            margin-bottom: 12px;
            @include maxWidth768(){
                margin-bottom: 4px;
            }
            &.online{
                color: $status_green;
            }
            &.offline{
                color: $status_red;
            }
        }
        p{
            color: var(--text-secondary);
        }
    }
}
.items-flex{
    display: flex;
    flex-wrap: wrap;
    row-gap: 30px;
    margin-bottom: 30px;
    @include maxWidth991{
        flex-direction: column;
        flex-wrap: unset;
        row-gap: unset;
    }
    @include maxWidth768(){
        margin-bottom: 20px;
    }
    &.offline{
        .users-item{
            &__btns{
                .btn_primary{
                    display: none;
                    @include maxWidth768(){
                        display: flex;
                    }
                }
            }
        }
    }
    .users-item{
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: var(--elements-hover);
        padding: 13px 18px;
        border-radius: 12px;
        width: calc(50% - 16px);
        &:nth-of-type(odd){
            margin-right: 32px;
            @include maxWidth991(){
                margin-right: 0;
            }
        }
        @include maxWidth991(){
            width: 100%;
            margin-right: 0;
            padding: 20px 0;
            border-bottom: 1px solid var(--line-light);
            background-color: unset;
            border-radius: unset;
        }
        @include maxWidth768(){
            flex-direction: column;
            align-items: flex-start;
        }
        &_left{
            display: flex;
            align-items: center;
            @include maxWidth1340(){
                width: calc(60% - 10px);
                margin-right: 10px;
            }
            @include maxWidth991(){
                width: auto;
            }
            @include maxWidth768(){
                width: 100%;
                margin-bottom: 12px;
            }
        }
        &__btns{
            display: flex;
            align-items: center;
            @include maxWidth1340(){
                width: 40%;
                justify-content: flex-end;
            }
            @include maxWidth991(){
                width: auto;
            }
            @include maxWidth768(){
                width: 100%;
                justify-content: space-between;
            }
            @include maxWidth420(){
                flex-wrap: wrap;
            }
            .btn_primary{
                margin-right: 18px;
                @include maxWidth1340(){
                    margin-right: 5px;
                    padding: 16px 20px;
                }
                @include maxWidth991(){
                    display: flex;
                    align-items: center;
                }
                @include maxWidth768(){
                    padding: 12px 18px;
                    font-size: 14px;
                    border-radius: 10px;
                    order: 2;
                }
                @include maxWidth420(){
                    padding: 12px;
                }
                b{
                    display: none;
                    @include maxWidth991(){
                        display: block;
                        font-weight: 700;
                        margin-left: 3px;
                    }
                }
            }
            .delete_btn{
                cursor: pointer;
                @include maxWidth768(){
                    border: 2px solid $status_red;
                    border-radius: 10px;
                    padding: 12px 24px;
                    order: 1;
                }
                span{
                    display: none;
                    @include maxWidth768(){
                        display: block;
                        font-size: 14px;
                        line-height: 16px;
                        font-weight: 700;
                        color: $status_red;
                    }
                }
                img{
                    width: 24px;
                    height: 24px;
                    @include maxWidth768(){
                        display: none;
                    }
                }
                svg{
                    width: 24px;
                    height: 24px;
                    @include maxWidth768(){
                        display: none;
                    }
                }
                &:hover {
                    svg path {
                        fill: $status_red;
                    }
                }
            }
        }
        &__img{
            width: 60px;
            height: 60px;
            margin-right: 13px;
            border-radius: 50%;
            overflow: hidden;
            @include maxWidth1340(){
                width: 44px;
                height: 44px;
            }
            img{
                min-width: 100%;
                min-height: 100%;
                object-fit: cover;
            }
        }
        &__text{
            width: calc(100% - 73px);
            @include maxWidth1340(){
                width: calc(100% - 44px);
            }
            .h4{
                color: var(--text-main);
                margin-bottom: 3px;
                @include maxWidth991(){
                    font-size: 16px;
                }
            }
            .type{
                color:  var(--text-secondary);
                @include maxWidth1340(){
                    font-size: 14px;
                }
            }
        }
    }
}