.videochat{
    width: 100%;
    height: 720px;
    margin: auto;
    border-radius: 20px;
    border: 1px solid $chat_bg;
    // background-color: $chat_bg;
    background: url(../../img/bg_player.png);
    background-color: rgba(0, 0, 0, 0.5);
    background-size: cover;
    position: relative;
    z-index: 2;
    overflow: hidden;
    max-height: 100vh;
    &:has(.videochat__navigation.visible) .center_btns{
        opacity: 1;
        visibility: visible;
    }
    &:has(.videochat__navigation.hidden) .center_btns{
        opacity: 0;
        visibility: hidden;
    }
    &:before{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5); 
        z-index: 1; 
    }
    @include maxWidth1600(){
        height: 620px;
        width: 80%;
    }
    @include maxWidth1340(){
        width: 100%;
    }
    &.opened {
        // @include maxWidth991(){
            position: fixed;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            z-index: 10;
        // }
    }
    @include maxWidth768(){
        height: calc(100vh - 120px);
        width: calc(100% + 20px);
        margin-left: -10px;
        border-radius: unset;
    }
    &__header{
        padding: 12px 20px;
        background-color: $chat_top_panel;
        border-radius: 20px 20px 0 0;
        border-bottom: 1px solid $color_nearly_black;
        position: relative;
        z-index: 2;
        @include flex-space-between();
        @include maxWidth768(){
            padding: 8px 50px;
            border-radius: unset;
            justify-content: center;
        }
        .left_header_info{
            display: flex;
            align-items: center;
            .note{
                display: flex;
                align-items: center;
                cursor: pointer;
                @include maxWidth768(){
                    width: 40px;
                    height: 40px;
                    justify-content: center;
                    background-color: $chat_additional_panel;
                    border-radius: 10px;
                    position: absolute;
                    top: 50px;
                    right: 16px;
                }
                img{
                    width: 24px;
                    height: 24px;
                    margin-right: 8px;
                    @include maxWidth768(){
                        margin-right: 0;
                    }
                }
                span{
                    color: $color_light_bright;
                    @include maxWidth768(){
                        display: none;
                    }
                }
            }
            .fullsize{
                width: 40px;
                height: 40px;
                margin-right: 24px;
                cursor: pointer;
                @include maxWidth768(){
                    z-index: 10;
                    background-color: $chat_additional_panel;
                    border: 1px solid $color_nearly_black;
                    border-radius: 10px;
                    @include flex-center();
                    position: absolute;
                    top: 50px;
                    left: 16px;
                    img{
                        width: 22px;
                        height: 22px;
                    }
                }
            }
        }
        .namechat{
            color: $color_bright;
            display: flex;
            align-items: center;
            span{
                display: flex;
                align-items: center;
                b{
                    margin-left: 5px;
                }
                @include maxWidth768(){
                    display: flex;
                    align-items: center;
                }
                @include maxWidth420(){
                    font-size: 12px;
                }
            }
            img{
                width: 24px;
                height: 24px;
                margin-right: 8px;
                @include maxWidth420(){
                    width: 20px;
                    height: 20px;
                }
            }
        }
        .recording{
            display: flex;
            align-items: center;
            cursor: pointer;
            @include maxWidth768(){
                position: absolute;
                top: 100px;
                right: 16px;
                background-color: $chat_additional_panel;
                border-radius: 8px;
                padding: 8px;
            }
            img{
                width: 24px;
                height: 24px;
                margin-left: 13px;
                @include maxWidth768(){
                    width: 12px;
                    height: 12px;
                    margin-left: 8px;
                }
            }
            span{
                @include maxWidth768(){
                    font-size: 10px;
                    line-height: 12px;
                    font-weight: 400;
                }
            }
        }
    }
    .fotouser{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        @include flex-center();
        img{
            min-width: 100%;
            min-height: 100%;
            object-fit: cover;
        }
        video {
            // min-width: 100%;
            min-height: 100%;
            object-fit: contain;
            position: relative;
            z-index: 2;
        }
    }
    .center_btns{
        position: absolute;
        top: 75px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        z-index: 2;
        transition: opacity 0.7s ease; 
        @include maxWidth1340(){
            left: 30%;
            transform: none;
        }
        @include maxWidth991(){
            left: unset;
            right: 20px;
            transform: unset;
            top: 70px;
        }
        @include maxWidth768(){
            top: 50px;
            // left: 50%;
            left: auto;
            right: unset;
            // transform: translateX(-50%);
            display: flex;
            width: 100%;
            z-index: 1;
            justify-content: center;
        }
    }
    .another_user{
        position: absolute;
        top: 85px;
        left: 20px;
        overflow: hidden;
        width: 212px;
        height: 120px;
        opacity: 0;
        display: flex;
        justify-content: center;
        display: flex;
        justify-content: flex-start;
        z-index: 2;
        &.active {
            opacity: 1;
        }
        video{
            border-radius: 14px;
            border: 1px solid var(--line-hard);
        }
        @include maxWidth768(){
            width: 80px;
            height: 148px;
            top: unset;
            left: unset;
            bottom: 100px;
            right: 16px;
            align-items: flex-end;
            video{
                height: auto;
            }
        }
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .companion{
        display: inline-flex;
        align-items: center;
        background-color: $chat_additional_panel;
        padding: 14px 16px;
        border-radius: 17px;
        border: 1px solid $color_nearly_black;
        color: $color_bright;
        margin-right: 13px;
        white-space: nowrap;
        @include maxWidth768(){
            padding: 8px 16px;
            border-radius: 10px;
            margin-right: 4px;
        }
        b{
            margin-left: 5px;
            @include maxWidth768(){
                font-size: 15px;
                line-height: 20px;
                margin-left: 0;
            }
        }
        @include maxWidth768(){
            span{
                display: none;
            }
        }
        img {
            @include maxWidth768(){
                width: 22px;
                height: 22px;
            }
        }
        &_gender {
            @include maxWidth768(){
                padding: 0;
                height: 40px;
                width: 40px;
                @include flex-center();
            }
            @include maxWidth420() {
                position: absolute;
                left: 16px;
                top: 50px;
            }
        }
    }
    &__navigation{
        position: absolute;
        bottom: 20px;
        width: 770px;
        left: calc(50% - 385px);
        // left: 50%;
        // transform: translateX(-50%);
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 25px 20px 25px 20px;
        background-color: $chat_additional_panel;
        border: 1px solid $color_nearly_black;
        border-radius: 20px;
        transition: opacity 0.7s ease; 
        z-index: 2;
        &.visible {
            opacity: 1;
            visibility: visible;
        }
        &.hidden {
            opacity: 0;
            visibility: hidden;
        }
        @include maxWidth991(){
            width: calc(100% - 10px);
            left: 50%;
            transform: translateX(-50%);
        }
        @include maxWidth768(){
            padding: 14px 12px;
            bottom: 10px;
            width: calc(100% - 32px);
            justify-content: space-between;
        }
        .settings{
            display: flex;
            align-items: center;
            @include maxWidth768(){
                position: absolute;
                bottom: 85px;
                left: 0;
            }
            &__item{
                border: 1px solid $color_light_bright;
                width: 50px;
                height: 50px;
                margin-right: 13px;
                border-radius: 12px;
                @include flex-center();
                cursor: pointer;
                &:last-of-type{
                    margin-right: 0;
                }
                @include maxWidth768(){
                    width: 40px;
                    height: 40px;
                    border-radius: 10px;
                    border: 1px solid $color_nearly_black;
                    background-color: $chat_additional_panel;
                    margin-right: 8px;
                }
                img{
                    width: 28px;
                    height: 28px;
                    @include maxWidth768(){
                        width: 22px;
                        height: 22px;
                    }
                }
                &.disabled {
                    border: 1px solid $status_red;
                }
            }
        }
        .buttons_start_end{
            display: flex;
            align-items: center;
            margin: 0 23px;
            @include maxWidth768(){
                margin: unset;
            }
            .btn {
                margin-left: 12px;
                width: 160px;
                border: none;
                padding-left: 20px;
                padding-right: 20px;
                @include maxWidth991(){
                    padding-left: 12px;
                    padding-right: 12px;
                    width: 140px;
                }
                @include maxWidth991(){
                    font-size: 14px;
                    line-height: 16px;
                }
                @include maxWidth560(){
                    width: auto;
                    padding-left: 8px;
                    padding-right: 8px;
                    height: 40px;
                    padding-top: 0;
                    padding-bottom: 0;
                    margin-left: 4px;
                }
                &.btn_icons {
                    @include maxWidth768(){
                        text-align: center;
                        padding-left: 12px !important;
                        padding-right: 12px !important;
                        span {
                            display: none;
                        }
                    }
                }
            }
            .btn_stop {
                background: $status_red;
            }
        }
        .recording_stop{
            display: flex;
            align-items: center;
            white-space: nowrap;
            cursor: pointer;
            .animate-spin{
                display: none;
                img{
                    margin: 0;
                }
            }
            &.loading {
                .record-icon{
                    display: none;
                }
                .animate-spin{
                    display: block;
                }
                .subtitle_medium{
                    margin-left: 10px;
                }
            }
            @include maxWidth768(){
                display: flex;
            }
            @include maxWidth420(){
                margin-left: 15px;
            }
            img{
                width: 50px;
                height: 50px;
                margin-right: 10px;
                @include maxWidth768(){
                    width: 40px;
                    height: 40px;
                    margin-right: unset;
                    margin-left: 10px;
                    order: 2;
                }
                @include maxWidth420(){
                    width: 25px;
                    height: 25px;
                    margin-left: 5px;
                }
            }
            span{
                color: $color_light_bright;
                &.record-icon {
                    @include maxWidth768(){
                        order: 1;
                        margin-right: 0;
                        margin-left: 12px;
                    }
                }
                @include maxWidth420(){
                    font-size: 11px;
                }
            }
            .subtitle_medium {
                width: 107px;
                @include maxWidth991(){
                    width: 96px;
                }
                @include maxWidth420(){
                    width: 50px;
                    white-space: normal;
                    text-align: right;
                }
            }
        }
    }
    &__loader {
        width: 100px;
        height: 100px;
        position: absolute;
        top: calc(50% - 50px);
        left: calc(50% - 50px);
        z-index: 2;
    }
    .record-icon {
        width: 50px;
        height: 50px;
        background: #fff;
        border-radius: 50%;
        position: relative;
        margin-right: 12px;
        &::after {
            content: '';
            background: $status_red;
            width: 36px;
            height: 36px;
            position: absolute;
            left: 7px;
            top: 7px;
            border-radius: 50%;
            transition: all .3s ease;
        }
        &::before {
            content: '';
            width: 42px;
            height: 42px;
            background: #000;
            position: absolute;
            border-radius: 50%;
            top: 4px;
            left: 4px;
        }
        &.recording::after {
            border-radius: 8px;
            width: 24px;
            height: 24px;
            left: 13px;
            top: 13px;
        }
    }
    .video-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .4);
    }
    .overlay-heading {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        @include flex-center();
        color: #fff;
        .h2{
            position: relative;
            z-index: 2;
            text-align: center;
        }
    }
}