// Headings
.h1 {
    font-size: 36px;
    line-height: 42px;
    font-weight: 700;
    @include maxWidth991(){
        font-size: 32px;
        line-height: 38px;
    }
}
.h2 {
    font-size: 24px;
    line-height: 30px;
    font-weight: 700;
    @include maxWidth991(){
        font-size: 20px;
        line-height: 24px;
    }
}
.h3 {
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    @include maxWidth991(){
        font-size: 16px;
        line-height: 20px;
    }
}
.h4 {
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    @include maxWidth991(){
        font-size: 12px;
        line-height: 14px;
    }
}
.h5 {
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
    @include maxWidth991(){
        font-size: 10px;
        line-height: 12px;
    }
}
.h6 {
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
    @include maxWidth991(){
        font-size: 8px;
        line-height: 10px;
    }
}

//Subtitles
.subtitle {
    &_big {
        font-size: 18px;
        line-height: 22px;
        font-weight: 500;
        &_underline {
            text-decoration: underline;
        }
        @include maxWidth991(){
            font-size: 16px;
            line-height: 20px;
        }
    }
    &_medium {
        font-size: 16px;
        line-height: 20px;
        font-weight: 500;
        &_underline {
            text-decoration: underline;
        }
        @include maxWidth991(){
            font-size: 14px;
            line-height: 18px;
        }
    }
    &_small {
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
        &_underline {
            text-decoration: underline;
        }
        @include maxWidth991(){
            font-size: 12px;
            line-height: 14px;
        }
    }
}

//Body fonts
.text {
    &_big {
        font-size: 18px;
        line-height: 22px;
        font-weight: 400;
        &_underline {
            text-decoration: underline;
        }
        @include maxWidth991(){
            font-size: 16px;
            line-height: 20px;
        }
    }
    &_medium {
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        &_underline {
            text-decoration: underline;
        }
        @include maxWidth991(){
            font-size: 14px;
            line-height: 18px;
        }
    }
    &_small {
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        &_underline {
            text-decoration: underline;
        }
        @include maxWidth991(){
            font-size: 12px;
            line-height: 14px;
        }
    }
}

