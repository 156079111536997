@use 'colors' as *;

html[theme="dark"] {
  --background: #{$c_bg};
  --elements: #{$c_el};
  --elements-hover: #{$c_el_hover};
  --input: #{$c_input};
  --text-main: #{$c_text_main};
  --text-secondary: #{$c_text_sec};
  --text-light: #{$c_text_light};
  --link: #{$c_link};
  --line-hard: #{$c_line_hard};
  --line-light: #{$c_line_light};
}

html[theme="light"] {
    --background: #{$c_bg_day};
    --elements: #{$c_el_day};
    --elements-hover: #{$c_el_hover_day};
    --input: #{$c_input_day};
    --text-main: #{$c_text_main_day};
    --text-secondary: #{$c_text_sec_day};
    --text-light: #{$c_text_light_day};
    --link: #{$c_link_day};
    --line-hard: #{$c_line_hard_day};
    --line-light: #{$c_line_light_day};
}