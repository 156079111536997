.btn {
    font-size: 16px;
    line-height: 18px;
    font-weight: 700;
    text-transform: uppercase;
    background: $btn_main;
    border: 1px solid $btn_main;
    color: $btn_text;
    border-radius: 14px;
    padding: 16px 32px;
    text-decoration: none;
    outline: none;
    -webkit-appearance: none;
    &_small {
        font-size: 14px;
        line-height: 16px;
    }
    &_primary {
        background: $btn_main;
        color: $btn_text;
        &:hover {
            background: $btn_hover;
            border-color: $btn_hover;
        }
        &:disabled {
            background: $btn_disabled;
            border-color: $btn_disabled;
        }
        &:focus,
        &:active {
            background: $btn_pressed;
            border-color: $btn_pressed;
        }
    }
    &_secondary {
        background: transparent;
        color: $btn_main;
        &:hover {
            border-color: $btn_hover;
            color: $btn_hover;
        }
        &:disabled {
            border-color: $btn_disabled;
            color: $btn_disabled;
        }
        &:focus,
        &:active {
            color: $btn_pressed;
            border-color: $btn_pressed;
        }
    }
    &_ghost {
        background: transparent;
        color: $btn_main;
        border: none;
        &:hover {
            color: $btn_hover;
        }
        &:focus,
        &:active {
            color: $btn_pressed;
        }
    }
    &_icons {
        position: relative;
        &:has(.btn_icons_left) {
            padding-right: 48px !important;
            text-align: right;
            @include maxWidth991(){
                padding-right: 36px !important;
            }
        }
        &:has(.btn_icons_right) {
            padding-left: 48px !important;
            text-align: left;
            @include maxWidth991(){
                padding-left: 36px !important;
            }
        }
        &_left {
            position: absolute;
            top: calc(50% - 12px);
            left: 40px;
            @include maxWidth991(){
                left: 30px;
            }
        }
        &_right {
            position: absolute;
            top: calc(50% - 12px);
            right: 40px;
            @include maxWidth991(){
                right: 30px;
            }
        }
        // &::before {
        //     content: '';
        //     margin-right: 12px;
        //     position: relative;
        //     top: 2px;
        // }
        // &::after {
        //     content: '';
        //     margin-left: 12px;
        //     position: relative;
        //     top: 2px;
        // }
    }
    &.add_friend{
        display: inline-flex;
        align-items: center;
        background-color: $status_green;
        padding: 13px 17px !important;
        border: 1px solid rgba(41, 107, 30, 1);
        border-radius: 14px;
        @include maxWidth768(){
            padding: unset !important;
            border-radius: 10px;
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
        }
        span{
            margin-right: 8px;
            color: rgba(27, 82, 18, 1);
            @include maxWidth768(){
                display: none;
            }
        }
        img{
            width: 24px;
            height: 24px;
            @include maxWidth768(){
                width: 20px;
                height: 20px;
            }
        }
    }
}