.subscription-card{
    margin-bottom: 20px;
    position: relative;
    overflow: hidden;
    @include maxWidth768(){
        padding: 20px !important;
    }
    &.unactive{
        .subscription-card__header{
            .btn{
                @include maxWidth991(){
                    font-size: 15px;
                }
                @include maxWidth768(){
                    width: 100%;
                }
            }
        }
        .subscription-card__benefits{
            border-bottom: unset;
            .flex-benefits{
                display: flex;
                flex-wrap: wrap;
                row-gap: 12px;
                width: 60%;
                @include maxWidth991(){
                    width: 70%;
                }
                @include maxWidth768(){
                    flex-wrap: unset;
                    flex-direction: column;
                    row-gap: unset;
                    width: 100%;
                }
                &__row{
                    display: flex;
                    align-items: center;
                    width: calc(50% - 12px);
                    margin-right: 0;
                    &:nth-of-type(odd){
                        margin-right: 24px;
                    }
                    @include maxWidth768(){
                        width: 100%;
                        margin-bottom: 16px;
                        &:nth-of-type(odd){
                            margin-right: 0;
                        }
                        &:last-of-type{
                            margin-bottom: 0;
                        }
                    }
                    img{
                        width: 24px;
                        height: 24px;
                        margin-right: 9px;
                    }
                    span{
                        color: var(--text-main);
                        @include maxWidth1340(){
                            font-size: 14px;
                        }
                        @include maxWidth991(){
                            font-size: 12px;
                        }
                        @include maxWidth768(){
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
    &__subtitle{
        margin-bottom: 24px;
        color: var(--text-secondary);
        @include maxWidth768(){
            margin-bottom: 12px;
        }
    }
    &__header{
        border-bottom: 1px solid var(--line-light);
        padding-bottom: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include maxWidth768(){
            padding-bottom: 20px;
            flex-direction: column;
            align-items: flex-start;
            justify-content: unset;
        }
        &-heading{
            display: flex;
            align-items: center;
            margin-right: 16px;
            @include maxWidth768(){
                flex-direction: column;
                align-items: flex-start;
            }
            span{
                color: var(--text-main);
                b{
                    color: $status_green;
                }
            }
        }
        .flex{
            @include maxWidth768(){
                justify-content: space-between;
                align-items: flex-start;
                width: 100%;
                margin-bottom: 20px;
            }
        }
        .btn-status{
            padding: 6px 16px;
            border-radius: 8px;
            color: var(--elements);
            &.active{
                background-color: $status_green;
            }
            &.unactive{
                background-color: $status_red;
            }
        }
        .delete{
            span{
                color: var(--text-secondary);
            }
            svg{
                path{
                    fill: var(--text-secondary);
                }
            }
            &:hover {
                svg path {
                    fill: $status_red;
                }
            }
        }
    }
    &__benefits{
        border-bottom: 1px solid var(--line-light);
        padding: 30px 0;
        @include maxWidth768(){
            padding: 20px 0;
        }
    }
    &__payment{
        padding-top: 30px;
        @include maxWidth768(){
            padding-top: 20px;
        }
    }
}
.flex-benefits{
    display: flex;
    @include maxWidth1340(){
        flex-wrap: wrap;
        row-gap: 12px;
    }
    @include maxWidth768(){
        flex-wrap: unset;
        flex-direction: column;
        row-gap: unset;
    }
    &__row{
        display: flex;
        align-items: center;
        // width: calc(25% - 24px);
        margin-right: 24px;
        @include maxWidth1340(){
            width: calc(50% - 12px);
            margin-right: 0;
            &:nth-of-type(odd){
                margin-right: 24px;
            }
        }
        @include maxWidth768(){
            width: 100%;
            margin-bottom: 16px;
            &:nth-of-type(odd){
                margin-right: 0;
            }
            &:last-of-type{
                margin-bottom: 0;
            }
        }
        img{
            width: 24px;
            height: 24px;
            margin-right: 9px;
        }
        span{
            color: var(--text-main);
        }
    }
}
.payment-wrapper{
    display: flex;
    align-items: flex-start;
    width: 50%;
    @include maxWidth1340(){
        width: 70%;
    }
    @include maxWidth768(){
        width: 100%;
        flex-direction: column;
        align-items: center;
    }
    &__visa-card{
        width: calc(70% - 32px);
        margin-right: 32px;
        position: relative;
        @include maxWidth768(){
            width: 100%;
            margin-right: 0;
            margin-bottom: 12px;
        }
        img{
            width: 100%;
        }
        .card_number{
            position: absolute;
            top: 30px;
            left: 30px;
            color:#FFFFFF;
        }
    }
    .btn-change-card{
        width: 30%;
        display: flex;
        align-items: center;
        padding: 19px 12px;
        @include maxWidth768(){
            width: auto;
        }
        img{
            width: 24px;
            height: 24px;
            margin-left: 8px;
            @include maxWidth991(){
                width: 20px;
                height: 20px;
            }
        }
        span{
            text-transform: uppercase;
            @include maxWidth991(){
                font-size: 16px;
            }
            color: var(--link);
        }
    }
}
.parrot-message{
    position: absolute;
    bottom: 0;
    right: 0;
    @include maxWidth768(){
        display: none;
    }
    &.unactive{
        .chat-message{
            width: 242px;
            height: 75px;
            background-size: 242px 75px;
            top: 60px;
            @include maxWidth1340(){
                top: 45px;
                left: -115px;
            }
            @include maxWidth991(){
                top: -20px;
            }
        }
        img{
            @include maxWidth991(){
                width: 255px;
            }
        }
    }
    .chat-message{
        background-image: url(../../img/parrot_message_bcg.png);
        background-repeat: no-repeat;
        background-size: 240px 104px;
        width: 240px;
        height: 104px;
        position: absolute;
        left: -100px;
        padding: 17px 12px 21px;
        color: #E9F0F5;
        @include maxWidth1340(){
            left: -131px;
            top: -22px;
        }
        @include maxWidth991(){
            width: 180px;
            height: 80px;
            background-size: 180px 80px;
            left: -93px;
            top: -12px;
        }
        &:before{
            display: block;
            content: "";
            width: 24px;
            height: 24px;
            background-image: url(../../img/icons/Notice.svg);
            background-repeat: no-repeat;
            position: absolute;
            top: -7px;
            left: -6px;
        }
    }
    img{
        @include maxWidth1340(){
            width: 220px;
        }
        @include maxWidth991(){
            width: 180px;
        }
    }
}
.transactions-history-card{
    overflow: hidden;
    &.card-widget{
        padding: unset;
    }
    &__heading{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 24px 32px;
        border-bottom: 4px solid var(--background);
        cursor: pointer;
        @include maxWidth768(){
            padding: 24px 20px;
        }
        img{
            width: 40px;
            height: 40px;
        }
    }
    &__content{
        display: none;
        transition: all .3s ease;
        &.active {
            display: block;
        }
        table{
            width: 100%;
            @include maxWidth768(){
                display: none;
            }
            tr{
                border-top: 1px solid  var(--line-light);
                &:last-of-type {
                    border-bottom: none;
                }
                th{
                    text-align: start;
                    color: var(--text-secondary);
                    padding: 24px;
                    &:first-of-type{
                        padding: 24px 24px 24px 32px;
                    }
                    &:last-of-type{
                        padding: 24px 32px 24px 24px;
                    }
                }
                td{
                    padding: 24px;
                    color: var(--text-main);
                    &:first-of-type{
                        padding: 24px 24px 24px 32px;
                    }
                    &:last-of-type{
                        padding: 24px 32px 24px 24px;
                    } 
                }
            }
        }
        .table-mobile{
            display: none;
            padding: 20px;
            @include maxWidth768(){
                display: block;
            }
            .table-row{
                border-bottom: 1px solid var(--line-light);
                padding: 8px 0;
                display: flex;
                &:first-of-type{
                    padding: 0 0 8px 0;
                }
                &:last-of-type{
                    padding: 8px 0 0 0;
                    border-bottom: unset;
                }
                &_left{
                    width: calc(70% - 12px);
                    margin-right: 12px;
                    .table-row__item{
                        &:last-of-type{
                            margin-bottom: 0;
                        }
                    }
                }
                &_right{
                    width: 30%;
                    .table-row__item{
                        &:last-of-type{
                            margin-bottom: 0;
                        }
                    }
                }
                &__item{
                    margin-bottom: 8px;
                    span{
                        color: var(--text-secondary);
                        margin-bottom: 4px;
                        display: block;
                    }
                    h4{
                        color: var(--text-main);
                    }
                }
            }
        }
    }
}
.upside-down {
    transform: rotate(180deg);
}