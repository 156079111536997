// footer
footer{
    width: 100%;
    background-color: var(--elements);
    border-top: 1px solid var(--line-hard);
    padding: 21px 0;
    @include flex-center();
    @include maxWidth991(){
        padding: 18px 16px;
        .additional_block{
            display: none;
        }
    }
}
.footer_rightpart{
    width: 70%;
    margin: 0 auto;
    @include flex-space-between();
    @include maxWidth991(){
        width: 100%;
        flex-direction: column;
    }
    & > * {
        margin-bottom: 12px;
    }
}
.footer_email {
    text-decoration: underline;
    color: var(--text-primary);
}
.footer_menu{
    display: flex;
    align-items: center;
    li{
        color: var(--text-secondary);
        margin-right: 31px;
        @include maxWidth768(){
            font-size: 14px;
            line-height: 14.5px;
            margin-right: 12px;
        }
        @include maxWidth420(){
            font-size: 12px;
            margin-right: 8px;
        }
        &:last-of-type{
            margin-right: 0;
        }
    }
}
.switch {
    position: relative;
    overflow: hidden;
    width: 74px;
    height: 38px;
    @include maxWidth768(){
        width: 46px;
        height: 24px;
    }
}
.switch input {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    opacity: 0;
}
.switch label {
    cursor: pointer;
}
.background {
    z-index: 1;
    position: absolute;
    width: 74px;
    height: 38px;
    border-radius: 42px;
    border: 2px solid $color_nearly_black;
    background:  var(--line-hard);
    transition: all 0.3s;
    @include maxWidth768(){
        width: 46px;
        height: 24px;
    }
}
.sun-moon {
    z-index: 2;
    position: absolute;
    left: 0;
    @include flex-center();
    height: 30px;
    width: 30px;
    margin: 4px 5px;
    background: $color_nearly_black;
    border-radius: 50%;
    background-image: url(../../img/icons/moon.svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 18px 18px;
    transition: all 0.5s ease;
    @include maxWidth768(){
        width: 18px;
        height: 18px;
        background-size: 12px 12px;
        margin: 3px;
    }
}
.switch input:checked ~ .sun-moon {
    left: calc(100% - 38px);
    background:  var(--line-hard);
    background-image: url(../../img/icons/sun.svg);
    background-repeat: no-repeat;
    background-position: center center;
    @include maxWidth768(){
        left: calc(100% - 24px);
    }
}
.switch input:checked ~ .background {
    border: 2px solid  var(--line-hard);
    background: var(--line-light);
}

.language {
    position: relative;
    & > span {
        display: flex;
        text-transform: uppercase;
        cursor: default;
    }
    &:hover .dropdown {
        display: block;
    }
    .dropdown {
        bottom: 20px;
        left: -15px;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        border-top: 1px solid var(--line-hard);
        width: 66px;
    }
}