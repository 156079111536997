.input{
    padding: 15px 24px;
    border-radius: 14px;
    background-color: var(--input);
    color: var(--text-main);
    display: block;
    transition: 0.3s ease;
    width: 300px;
    -webkit-appearance: none;
    &::placeholder{
        color: var(--text-secondary);
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        // opacity: 1;
    }
    &:focus{
        outline: 1px solid var(--link);
    }
    &.error{
        outline: 1px solid $status_red;
        margin-bottom: 4px;
    }
    &.valid{
        outline: 1px solid $status_green;
    }
}
.input_icon{
    padding: 15px 24px 15px 58px;
    background-image: url(../img/icons/sms.svg), url(../img/icons/show_interface.svg);
    background-repeat: no-repeat;
    background-position: 24px center, 91% center;
    background-size: 24px 24px, 24px 24px;
}
.input_div{
    position: relative;
    display: inline-block;
    .input_password{
        padding: 15px 54px 15px 24px;
        &.eyeInvisible ~ .icon_eye{
            background-image: url(../img/icons/eyeInvisible.svg);
        }
    }
    .icon_eye{
        cursor: pointer;
        display: block;
        width: 24px;
        height: 24px;
        position: absolute;
        top: 15px;
        background-image: url(../img/icons/show_interface.svg);
        background-repeat: no-repeat;
        right: 24px;
    }
}
.error{
    font-weight: 400;
    font-size: 14px;
    line-height: 17.5px;
    color: $status_red;
    display: flex;
    justify-content: flex-start;
    padding-left: 13px;
    padding-right: 13px;
}
.success {
    color: $status_green;
}
textarea.input {
    width: 100%;
    height: 160px;
    resize:none
}

.custom_chekbox{
    text-align: start;
    display: block;
    position: relative;
    padding-left: 40px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-bottom: 12px;
    @include maxWidth768(){
        margin-bottom: 8px;
    }
    &:last-of-type{
        margin-bottom: 24px;
        @include maxWidth768(){
            margin-bottom: 16px;
        }
    }
    input{
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }
    .checkmark {
        position: absolute;
        top: 5px;
        left: 0;
        height: 24px;
        width: 24px;
        background-color: transparent;
        border: 1px solid var(--text-secondary);
        border-radius: 8px;
    }
    &__text{
        color: var(--text-secondary);
        padding: unset;
        text-align: start;
        .link{
            color: var(--link);
        }
    }
    .error {
        display: block;
    }
  }
.custom_chekbox input:checked ~ .checkmark{
    background-color: var(--link);
    border: unset;
  
}
.custom_chekbox input:checked ~ .checkmark:after {
    display: block;
}
.custom_chekbox .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
.custom_chekbox .checkmark:after {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 9.5px;
    height: 7px;
    background: url(../../assets/img/icons/done.svg);
    background-position: center;
    background-repeat: no-repeat;
}

.select-container {
    margin-bottom: 24px;
    @include maxWidth768() {
        margin-bottom: 16px;
    }
}
.select {
    border-radius: 14px;
    width: 100%;
    height: 50px;
    background-color: var(--input) !important;
    color: var(--text-main) !important;
    border-radius: 14px !important;
    text-align: left !important;
    padding-left: 12px;
    padding-right: 12px;
    border: none !important;
    cursor: pointer !important;
    & * {
        outline: none !important;
        border: none !important;
    }
}
.select-option {
    background-color: var(--input) !important;
    color: var(--text-main) !important;
    cursor: pointer !important;
    text-align: left !important;
    &:hover {
        background-color: var(--elements) !important;
    }
}
.select-menu {
    background-color: var(--input) !important;
    border-radius: 14px !important;
    overflow: hidden;
    border: 1px solid var(--line-hard);
}
.select-input {
    color: var(--text-main) !important;
}
.select-placeholder,
.select-value {
    color: var(--text-main) !important;
}