@use 'assets/styles/themes' as *;
@import 'assets/styles/mixins.scss';
@import 'assets/styles/typography';
@import 'assets/styles/colors';
@import 'assets/styles/buttons';
@import 'assets/styles/inputs';
@import 'assets/styles/components/footer';
@import 'assets/styles/components/navbar';
@import 'assets/styles/components/videochat';
@import 'assets/styles/components/popups';
@import 'assets/styles/pages/profile';
@import 'assets/styles/pages/friends.scss';
@import 'assets/styles/pages/subscriptions.scss';
@import 'assets/styles/pages/videos.scss';
@import 'assets/styles/main';

* {
    box-sizing: border-box;
    -webkit-appearance: none;
}
body {
    margin: 0;
    font-size: 16px;
    background-color: var(--background);
    color: var(--text-main);
    overflow-x: hidden;
}