.popup {
    position: absolute;
    width: 100vw;
    min-height: 100vh;
    top: 0;
    left: 0;
    @include flex-center();
    &__overlay {
        background: rgba(4, 12, 20, 0.56);
        width: 100%;
        height: 100%;
        position: fixed;
        z-index: 10;
        top: 0;
        left: 0;
        overflow: scroll;
    }
    &__content {
        width: 600px;
        background: var(--elements);
        border-radius: 30px;
        padding: 24px 50px 50px 50px;
        position: relative;
        z-index: 11;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        @include maxWidth768(){
            width: 100%;
            margin: 0 16px;
            padding: 32px;
        }
        &_small{
            padding: 60px 130px;
            @include maxWidth768(){
                padding: 32px;
            }
        }
    }
    &__close {
       z-index: 12;
       position: absolute;
       right: 12px;
       top: 12px;
       cursor: pointer; 
       img{
            width: 32px;
            height: 32px;
       }
       &_left{
            top: 30px;
            left: 30px;
        }
    }
    &__heading{
        color:  var(--text-main);
        margin-bottom: 40px;
        @include maxWidth768(){
            margin-bottom: 24px;
        }
    }
    &__icon-parrot{
        width: 90px;
        height: 95px;
        margin: 0 auto 40px;
        img{
            width: 100%;
        }
    }
    .btns-flex{
        width: 100%;
        display: flex;
        .btn{
            width: calc(50% - 10px);
            @include maxWidth768(){
                width: calc(50% - 6px);
                padding: 12px 23px;
                border-radius: 10px;
                font-size: 14px;
                line-height: 16px;
            }
            &:first-of-type{
                margin-right: 20px;
                @include maxWidth768(){
                    margin-right: 12px;
                }
            }
        }
    }
    &__description {
        display: block;
        color: var(--text-secondary);
        margin-bottom: 30px;
        @include maxWidth768(){
            margin-bottom: 24px;
        }
    }
    &__descr-icon{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
        @include maxWidth420(){
            align-items: flex-start;
        }
        &_green{
            span{
                color: $status_green!important;
            }
        }
        img{
            width: 32px;
            height: 32px;
            margin-right: 8px;
        }
        span{
            color: var(--text-secondary);
        }
    }
    &__form{
        .input_div{
            width: 100%;
            margin-bottom: 20px;
            input{
                width: 100%;
            }
        }
        .btn{
            width: 100%;
        }
    }
    &__inputs-flex{
        display: flex;
        @include maxWidth768(){
            flex-direction: column;
        }
        .input_div {
            width: calc(50% - 10px);
            margin-bottom: 30px;
            @include maxWidth768(){
                width: 100%;
                margin-bottom: 20px;
            }
            &:first-of-type{
                margin-right: 20px;
                @include maxWidth768(){
                    margin-right: 0;
                }
            }
        }
    }
    &__logout{
        width: 100%;
        &-subheading{
            display: block;
            color: var(--text-main);
            margin-bottom: 40px;
        }
    }
    &__form-card{
        .input_div{
            width: 100%;
        }
        .btn{
            display: block;
            &.btn_primary{
                margin-bottom: 20px;
            }
            &.btn_secondary{
                margin-bottom: 30px;
            }
        }
    }
    &__register-subscription{
        width: 100%;
        .popup__heading{
            br{
                display: none;
                @include maxWidth768(){
                    display: block;
                }
            }
        }
        .h3{
            color: var(--text-main);
            margin-bottom: 30px;
            span{
                color: $status_green;
            }
        }
    }
    &__cancel-subscription{
        width: 100%;
        .benefits-col{
            width: 62%;
            margin: 0 auto 40px;
            @include maxWidth768(){
                width: 55%;
            }
            @include maxWidth560(){
                width: 100%;
            }
            &__row{
                @include maxWidth560(){
                    justify-content: center;
                }
                @include maxWidth420(){
                    width: 100%;
                    justify-content: unset;
                    .subtitle_big{
                        font-size: 13px;
                    }
                }
            }
        }
        .popup__description{
            margin-bottom: 40px;
            span{
                color: var(--text-main);
                b{
                    color: $status_red;
                }
            }
        }
        .btns-flex{
            @include maxWidth768(){
                flex-direction: column;
                .btn{
                    width: 100%;
                    &:first-of-type{
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }
    &__congratulations{
        .popup__description{
            margin-bottom: 40px;
        }
        .btn{
            width: 100%;
        }
    }
    &__change-password{
        .popup__form{
            .btn{
                margin-bottom: 40px;
            }
            .question{
                justify-content: center;
            }
        }
    }
    &__verify-password{
        .btn{
            margin-bottom: 24px;
        }
        &_resend-request{
            color: var(--text-light);
            a{
                color: var(--text-main);
            }
        }
    }
    &__delete-photo{
        &_descr{
            display: block;
            color: var(--text-main);
            margin-bottom: 40px;
        }
        .btns-flex{
            @include maxWidth560(){
                flex-direction: column;
                .btn{
                    width: 100%;
                    &:first-of-type{
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }
    &__unfollow-friend{
        &_descr{
            color: var(--text-main);
            margin-bottom: 40px;
            display: block;
            a{
                color: var(--link);
            }
        }
        .btns-flex{
            @include maxWidth560(){
                flex-direction: column;
                .btn{
                    width: 100%;
                    &:first-of-type{
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }
    &__report-video {
        width: 100%;
    }
}