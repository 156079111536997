.navbar {
    background: var(--elements);
    border-bottom: 1px solid var(--line-hard);
    width: 100%;
    height: 80px;
    @include maxWidth1600() {
        height: 60px;
    }
    .overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: #040C14;
        opacity: .56;
    }
    .logo-wrap {
        margin-right: -200px;
        @include maxWidth1340() {
            margin-right: 0;
        }
    }
    .breed__dropdown {
        position: absolute;
        background: var(--elements);
        top: 80px;
        width: 550px;
        opacity: 0;
        z-index: 10;
        visibility: hidden;
        opacity: 0;
        transition: all 0.3s ease;
        box-shadow: 0px 4px 27px 0px rgba(0, 0, 0, 0.14);
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
        border-left: 1px solid var(--line-hard);
        border-right: 1px solid var(--line-hard);
        border-bottom: 1px solid var(--line-hard);
        @include maxWidth1600() {
            top: 60px;
        }
        @include maxWidth991() {
            left: 0;
        }
        @include maxWidth560() {
            width: 100%;
        }
        &.active {
            visibility: visible;
            opacity: 1;
        }
        .breed_warning {
            @include flex-center();
            padding: 20px;
            & > img {
                margin-right: 16px;
            }
        }
        & > .btn {
            margin-bottom: 20px;
            margin-left: 20px;
            margin-right: 20px;
        }
        .btn {
            position: relative;
            &:disabled::before {
                content: url(../../img/icons/lock.svg);
                position: absolute;
                top: -20px;
                left: -20px;
            }
        }
    }
    &__breed {
        .h3 {
            @include maxWidth1600() {
                font-size: 16px;
            }
        }
        button {
            @include flex-center();
            @include maxWidth991() {
                width: 110px;
            }
            span {
                margin-left: 16px;
                @include maxWidth991() {
                    margin-left: 8px;
                }
            }
        }
        img {
            @include maxWidth991() {
                @include square(32px);
            }
        }
    }
    .breed__list {
        position: relative;
        max-height: 100vh;
        overflow: scroll;
        height: calc(100vh - 70px);
        @include maxWidth1600() {
            height: calc(100vh - 160px);
        }
    }
    .breed__item {
        @include flex-space-between();
        padding: 20px;
        border-bottom: 1px solid var(--line-light);
        .img-wrap {
            @include square(60px);
            border-radius: 14px;
            overflow: hidden;
            margin-right: 12px;
            img {
                min-height: 100%;
                min-width: 100%;
                object-fit: cover;
            }
        }
    }
    .notification__list {
        position: relative;
        max-height: 350px;
        overflow: scroll;
    }
    &__notification {
        margin-left: 20px;
        position: relative;
        @include maxWidth991() {
            margin-left: 0;
        }
        @include maxWidth560() {
            position: static;
        }
        & > button {
            position: relative;
            @include maxWidth991() {
                @include square(36px);
            }
            &.unread svg path{
                fill: #EAE347;
            }
            &.unread::before {
                content: '';
                @include square(12px);
                position: absolute;
                right: 8px;
                top: 4px;
                background: $status_red;
                border-radius: 50%;
                border: 2px solid var(--elements);
            }
        }
    }
    .notification__popup {
        position: absolute;
        background: var(--elements);
        top: 54px;
        width: 560px;
        right: -24px;
        z-index: 10;
        visibility: hidden;
        opacity: 0;
        transition: all 0.3s ease;
        box-shadow: 0px 4px 27px 0px rgba(0, 0, 0, 0.14);
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
        border-left: 1px solid var(--line-hard);
        border-right: 1px solid var(--line-hard);
        border-bottom: 1px solid var(--line-hard);
        @include maxWidth991() {
            top: 52px;
            width: 460px;
        }
        @include maxWidth560() {
            width: 100%;
            right: 0;
        }
        &.active {
            visibility: visible;
            opacity: 1;
        }
        .popup__head {
            @include flex-space-between();
            padding: 20px;
            .icon-wrap {
                background: var(--elements-hover);
                border-radius: 50%;
                min-width: 60px;
                @include square(60px);
                @include flex-center;
                margin-right: 16px;
                position: relative;
                &.unread::before {
                    content: '';
                    @include square(12px);
                    position: absolute;
                    right: 18px;
                    top: 14px;
                    background: $status_red;
                    border-radius: 50%;
                    border: 2px solid var(--elements-hover);
                }
                &.unread svg path{
                    fill: #EAE347;
                }
            }
        }
        .notification__item {
            @include flex-space-between();
            padding: 20px;
            border-bottom: 1px solid var(--line-light);
            @include maxWidth420() {
                display: block;
            }
            .img-wrap {
                @include square(60px);
                border-radius: 50%;
                overflow: hidden;
                margin-right: 12px;
                img {
                    min-height: 100%;
                    min-width: 100%;
                    object-fit: cover;
                }
            }
            .subtitle_big {
                @include maxWidth420() {
                    max-width: 120px;
                    word-wrap: break-word;
                }
            }
            .subtitle_small {
                color: var(--text-secondary);
            }
            .btn {
                font-size: 14px;
                line-height: 16px;
                padding-left: 8px;
                padding-right: 8px;
                width: 164px;
                height: 50px;
                @include maxWidth991() {
                    width: 144px;
                }
                @include maxWidth560() {
                    width: auto;
                    min-width: 50px;
                }
                @include maxWidth420() {
                    display: flex;
                    justify-content: center;
                    margin-top: 10px;
                    width: 100%;
                    height: 40px;
                }
                &.add_friend {
                    span {
                        @include maxWidth420() {
                            display: block;
                        }
                    }
                }
            }
            .h4 {
                @include maxWidth560() {
                    font-size: 16px;
                    line-height: 20px;
                }
            }
        }
    }
    &__right {
        @include flex-center();
        .btn {
            @include maxWidth1600() {
                padding: 12px 24px;
            }
        }
    }
    &__profile {
        padding-bottom: 12px;
        margin-bottom: -12px;
        .profile-logo-wrap {
            margin-right: 12px;
            display: block;
            border-radius: 50%;
            overflow: hidden;
            @include square(54px);
            @include maxWidth991() {
                @include square(40px);
                margin-right: 4px;
            }
            img {
                min-width: 100%;
                min-height: 100%;
                object-fit: cover;
            }
        }
        @include flex-center();
        margin-left: 12px;
        .subtitle_medium {
            color: var(--text-secondary);
        }
        .h3 {
            @include flex-center(); 
        }
        &:hover .dropdown {
            display: block;
        }
    }
    .notification_empty {
        padding-top: 50px;
        padding-bottom: 50px;
        @include flex-center();
        p {
            color: var(--text-secondary);
        }
    }
    .dropdown {
        top: 80px;
        z-index: 10;
        @include maxWidth1600() {
            top: 60px;
        }
        @include maxWidth991() {
            right: 0;
        }
    }
    .logo-wrap {
        img.desktop-hidden {
            max-height: 36px;
        }
    }
}
.dropdown {
    width: 220px;
    position: absolute;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    overflow: hidden;
    display: none;
    box-shadow: 0px 4px 27px 0px rgba(0, 0, 0, 0.14);
    border-left: 1px solid var(--line-hard);
    border-right: 1px solid var(--line-hard);
    border-bottom: 1px solid var(--line-hard);
    a, button {
        display: block;
        width: 100%;
        padding: 16px 20px;
        color: var(--text-main);
        border: none;
        border-top: 1px solid var(--line-hard);
        background: var(--elements);
        transition: all .3s ease;
        outline: none;
        &:hover {
            background: var(--elements-hover);
        }
        &:first-of-type {
            border-top: none;
        }
    }
}
.layout_auth {
    .navbar {
        .logo-wrap {
            margin-right: 0;
        }
    }
}



.text_privacy ul{
    padding-left: 20px;
}
.text_privacy li{
    margin-top: 20px;
    font-size: 20px;
    font-weight: 400;
    line-height: 23.4px;
    text-align: left;
    list-style: disc;
}
.text_privacy a{
    margin-top: 20px;
    font-size: 20px;
    font-weight: 400;
    line-height: 23.4px;
    text-align: left;
}
.text_privacy p{
    margin-top: 20px;
    font-size: 20px;
    font-weight: 400;
    line-height: 23.4px;
    text-align: left;
}
.text_privacy h3{
    text-align: left;
    font-size: 20px;
    line-height: 24px;
    margin-top: 24px;
    font-weight: 700;
}

.text_privacy h2{
    margin-bottom: 0;
    margin-top: 48px;
    font-weight: 700;
}

.text_privacy h1 {
    font-size: 28px;
    font-weight: 700;
    line-height: 32.76px;
}

.text_privacy ol{
    padding-left: 20px;
}

.text_privacy ol li{
    list-style: auto;
}

body:has(.text_privacy) .navbar .logo-wrap{
    margin: 0;
}

body:has(.text_privacy):has(.navbar__breed) .navbar .logo-wrap {
    margin-right: -200px;
}

@media screen and (max-width: 1340px) {
    body:has(.text_privacy):has(.navbar__breed) .navbar .logo-wrap {
        margin-right: 0;
    }
}

.text_privacy{
    max-width: 900px;
    margin: 0 auto;
}

.tabs{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin: 20px auto 44px;
    background: rgba(151, 175, 210, 0.12);
    padding: 4px;
    border-radius: 18px;
    width: fit-content;
    max-width: 100%;
    overflow: scroll;
    -ms-overflow-style: none; 
    scrollbar-width: none;
}
.tabs::-webkit-scrollbar {
    display: none;
  }

.tabs li a{
    padding: 12px;
    border-radius: 14px;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: rgba(148, 168, 180, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all .3s ease;
    text-decoration: none;
    white-space: nowrap;
}

.tabs li.active a{
    background: rgba(8, 22, 42, 1);
    color: #fff;
}
